
.mainHero{
    width: 70rem;
    justify-content: center;
    align-items: center ;
    margin-left: 4rem;
}
.imagees {
    /* width: 40rem; */
    
    /* padding: 1rem; */
}

.sideimage {
    /* height: 90%; */
}

.imageside {
    /* padding: 0.5rem; */
    /* width: 30rem; */
    
    
}